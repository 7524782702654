import React, { Component } from "react"
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { graphql } from 'gatsby'
import Blockquote from 'components/blockquote';
import DatePicker from 'components/form/datepicker/'
import Layout from "templates/basic/"
import SEO from 'components/seo'
import NumberInput from 'components/form/number/'
import parse from 'html-react-parser';
import Upload from 'components/form/upload/';
import State from 'components/form/state/';
import TextInput from 'components/form/textinput/';
import RadioButton from 'components/form/radiobutton/'
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'components/form/select/';
import style from './grantApplication.module.scss';

export default class GrantApplication extends Component {

	constructor(props) {
		super(props);
		this.recaptchaComplete = this.recaptchaComplete.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.timerID = null;

		this.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
		this.zipRegExp = /\d{5}-?(\d{4})?/
		this.formSchema = Yup.object().shape({
			determinationLetter: Yup.string()
				.required('Please upload the determination letter.'),
			financials: Yup.string()
				.required('Please upload the previous year&rsquo;s financials.'),
			localOrganizationBudget: Yup.string()
				.required('Please upload the organization budget.'),
			projectBudget: Yup.string()
				.required('Please upload the project budget.'),
			organization: Yup.string()
				.required('Please enter the name of the organization.'),
			address: Yup.string()
				.required('Please enter the address of the organization.'),
			city: Yup.string()
				.required('Please enter the city of the organization.'),
			state: Yup.string()
				.required('Please select the state that the organization is located in.'),
			zip: Yup.string()
				.matches(this.zipRegExp, 'Please enter a valid zip code')
				.required('Please enter the zip code of the organization.'),
			ein: Yup.string()
				.required('Please enter the tax identification number of the organization.'),
			orgPhone: Yup.string()
				.matches(this.phoneRegExp, 'Please enter a valid phone number.')
				.required('Please enter the phone number of the organization.'),
			contact: Yup.string()
				.required('Please enter the name of the contact person regarding this application.'),
			contactTitle: Yup.string()
				.required('Please enter the title of the contact person regarding this application.'),
			contactPhone: Yup.string()
				.matches(this.phoneRegExp, 'Please enter a valid phone number.')
				.required('Please enter the phone number of the contact person regarding this application.'),
			contactEmail: Yup.string()
				.email('Please enter a valid email address.')
				.required('Please enter your email address.'),
			// orgType: Yup.string()
			// .required('Please select the organization type.'),
			orgSummary: Yup.string()
				.required('Please enter a brief summary of organization history.'),
			orgValues: Yup.string()
				.required('Please enter the purpose, vision, values of the organization.'),
			orgDateEstablished: Yup.date()
				.required('Please enter the date that the organization was established.'),
			projectName: Yup.string()
				.required('Please enter the name of the project.'),
			communityImpact: Yup.string()
				.required('Please enter the community(ies) that this project will impact.'),
			projectDate: Yup.date()
				.required('Please select project start date.'),
			totalProjectBudget: Yup.number()
				.required('Please enter the total budget for the project.'),
			monetaryRequest: Yup.number()
				.required('Please enter the dollar amount requested for the project.'),
			// projectType: Yup.string()
			// .required('Please select the type of project.'),
			projectAddresses: Yup.string()
				.required('Please enter the challenges that this project addresses.'),
			howMeetGoal: Yup.string()
				.required('Please enter how the project goal(s) will be met.'),
			activitySeeking: Yup.string()
				.required('Please enter the activities for which you are seeking funding.'),
			whoDoesActivities: Yup.string()
				.required('Please enter who will carry out those activities.'),
			measureImpact: Yup.string()
				.required('Please enter how you will quantify the impact of your project.'),
			howHearAbout: Yup.string()
				.required('Please enter how you heard about the Danos Foundation.'),
		});
		this.state = {
			recaptcha: '',
			recaptchaError: false,
			success: false,
		}
		this.initialValues = {
			determinationLetter: '',
			financials: '',
			projectBudget: '',
			localOrganizationBudget: '',
			organization: '',
			legalName: '',
			address: '',
			city: '',
			state: '',
			zip: '',
			ein: '',
			orgPhone: '',
			website: '',
			social: '',
			contact: '',
			contactTitle: '',
			contactPhone: '',
			contactEmail: '',
			orgType: '',
			orgSummary: '',
			orgDescription: '',
			boardMembers: '',
			orgBudget: '',
			projectName: '',
			projectAddresses: '',
			communityImpact: '',
			howSelected: '',
			projectGoals: '',
			howMeetGoal: '',
			activitySeeking: '',
			whoDoesActivities: '',
			activityTimeFrame: '',
			howActivitiesBenefit: '',
			longTermFunding: '',
			projectDate: '',
			successCriteria: '',
			measureImpact: '',
			whoEvaluateProject: '',
			evaluationResults: '',
			monetaryRequest: '',
			totalProjectBudget: '',
			agreeToUpdates: '',
			agreeToReport: '',
			additionalInfo: '',
			howHearAbout: '',
			danosEmployeeName: '',
			env: 'GRANT_APPLICATION_FORM',
			mailto: 'email1',
		}
	}

	recaptchaComplete(value) {
		this.setState({
			recaptcha: value,
			recaptchaError: false,
		});
	}

	componentWillUnmount() {
		if (this.timerID !== null) clearTimeout(this.timerID);
		this.timerID = null;
	}

	submitForm(values, { setSubmitting, resetForm }) {
		for (const field in values) {
			if (Object.hasOwnProperty.call(values, field)) {
				const value = values[field];
				if (Object.prototype.toString.call(value) === '[object Date]') {
					// Create a date object from the timestamp
					var date = new Date(value);

					// Create a list of names for the months
					var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

					// return a formatted date
					values[field] = months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
				}
			}
		}
		if (!this.state.recaptcha) {
			setSubmitting(false);
			this.setState({
				recaptchaError: true,
			});
			return;
		}
		// Extract email from form values
		const userEmail = values.contactEmail;

		// Include the user's email in the mailto field
		const mailtoList = `foundation@danos.com,${userEmail}`;

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(Object.assign({}, values, { recaptcha: this.state.recaptcha, mailto: mailtoList })),
		};
		fetch(`${process.env.GATSBY_ZMS_API_URL}contact/mail/`, options)
			.then(async function (response) {
				if (!response.ok) {
					const body = await response.json();
					throw new Error(body.errorMessage);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					success: true,
				});
				if (this.timerID) clearTimeout(this.timerID);
				this.timerID = setTimeout(() => {
					this.timerID = null;
				}, 3000);

			})
			.catch(function (err) {
				alert(err);
			})
			.finally(function () {
				setSubmitting(false);
				resetForm();
			})
			;
	}

	render() {
		// const options = [
		// 	'Welfare',
		// 	'Education',
		// 	'Environment',
		// 	'Healthcare',
		// 	'Other'
		// ];
		const communityOptions = [
			'West Texas',
			'South Texas',
			'East Texas',
			'North Texas',
			'North Louisiana',
			'South Louisiana',
			'West Louisiana',
			'East Louisiana',
			'Pennsylvania',
			'Other',
		];
		const radioOptions = [
			{ label: 'Social media', value: 'social_media' },
			{ label: 'Danos website', value: 'danos_website' },
			{ label: 'News publication', value: 'news_publication' },
			{ label: 'Word of mouth', value: 'word_of_mouth' },
			{ label: 'Danos employee Name', value: 'danos_employee_name' },
			{ label: 'Previous recipient', value: 'previous_recipient' },
			{ label: 'Other', value: 'other' },
		];

		const filesAccepted = ".jpg,.jpeg,.png,.gif,.txt,.docx,.doc,.xls,.xlsx,.zip,.pdf,.mov,.mp4,.ogg,.ogv,.vcf,.rtf"

		return (
			<>
				<Layout image="/userfiles/Foundation Forms/foundation header v3.jpg" title="Danos GIVES Grant Application" enableDanosFoundation={false} enableFooterTabs={true}>
					<section className="intro">
						<div className="grid-container">
							<div className="grid-x">
								<div className="cell small-12">
									<h1>Grant Application</h1>
									{/* <p className={style.disclaimer}>*denotes required fields</p> */}
									{/* <strong>This application must be completed in one sitting. Please take time to gather all the required information before beginning the application process.</strong> */}
								</div>
							</div>
							<div className="grid-x grid-margin-x">
								<div className="cell small-12">
									<Formik
										initialValues={this.initialValues}
										validationSchema={this.formSchema}
										onSubmit={this.submitForm}
									>
										{({ values, setFieldValue, isSubmitting }) => (
											<Form id="grantApplicationForm" method="post" encType="multipart/form-data">

												<div className={`${this.state.success ? style.hide : 'showMe'}`}>
													<div className={style.sectionTitle}>Section 1: Required Documents</div>
													<Field name="determinationLetter" setFieldValue={setFieldValue} label="IRS 501(c) 3 or 170(b) non-profit organization determination letter:*" component={Upload} accept={filesAccepted} />
													<Field name="financials" setFieldValue={setFieldValue} label="Financials or previous year’s tax return:*" component={Upload} accept={filesAccepted} />
													<Field name="localOrganizationBudget" setFieldValue={setFieldValue} label="Organization budget (local chapter):*" component={Upload} accept={filesAccepted} />
													<Field name="projectBudget" setFieldValue={setFieldValue} label="Grant project budget:*" component={Upload} accept={filesAccepted} />

													<div className={style.sectionTitle}>Section 2: Contact Information</div>
													<div className="grid-x grid-margin-x">
														<Field className="cell" value={values.organization} name="organization" label="Name of Organization:*" component={TextInput} />
														<Field className="cell" value={values.legalName} name="legalName" label="Legal Name, if different:" component={TextInput} />
														<Field className="cell" value={values.address} name="address" label="Address:*" component={TextInput} />
													</div>
													<div className="grid-x grid-margin-x medium-up-3">
														<Field className="cell" value={values.city} name="city" label="City:*" component={TextInput} />
														<Field className="cell" value={values.state} name="state" label="State:*" component={State} />
														<Field className="cell" value={values.zip} name="zip" label="Zip:*" component={TextInput} maxLength={10} />
													</div>
													<div className="grid-x grid-margin-x medium-up-2">
														<Field className="cell" value={values.ein} name="ein" label="Tax Identification Number:*" component={TextInput} />
														<Field className="cell" value={values.orgPhone} name="orgPhone" label="Organization Phone:*" component={TextInput} />
														<Field className="cell" value={values.website} name="website" label="Website:" component={TextInput} />
														<Field className="cell" value={values.social} name="social" label="Social media sites – Facebook, Twitter, Instagram, LinkedIn, Snapchat, Tumbler, etc:" component={TextInput} />
														<Field className="cell" value={values.contact} name="contact" label="Name of contact person regarding this application:*" component={TextInput} />
														<Field className="cell" value={values.contactTitle} name="contactTitle" label="Title:*" component={TextInput} />
														<Field className="cell" value={values.contactPhone} name="contactPhone" label="Contact Person Phone:*" component={TextInput} />
														<Field className="cell" value={values.contactEmail} name="contactEmail" label="Email:*" component={TextInput} />
														{/* <Field className="cell" name="orgType" label="Organization Type:*" options={options} placeholder="-- Please select --" component={Select}/> */}
													</div>

													<div className={style.sectionTitle}>Section 3: Organization Information</div>
													<Field name="orgSummary" value={values.orgSummary} label="Brief summary of organization history:*" maxLength={1000} component={TextInput} lines={5} />
													<DatePicker name="orgDateEstablished" label="Date established:*" />
													<Field name="orgValues" value={values.orgValues} label="Purpose, vision, values:*" maxLength={1000} component={TextInput} lines={5} />
													<Field name="orgDescription" value={values.orgDescription} label="Brief description of organization’s current initiatives, including any service statistics, strengths and accomplishments. Please highlight any new or unique activities." maxLength={1000} component={TextInput} lines={5} />

													<div className={style.sectionTitle}>Section 4: Project Information</div>
													<div className="grid-x grid-margin-x medium-up-2">
														<Field className="cell" value={values.projectName} name="projectName" label="Name of project that grant will support.*" maxLength={100} component={TextInput} lines={5} />
														<DatePicker className="cell" name="projectDate" label="If funded, when will the project begin:*" />
														<Field className="cell" value={values.communityImpact} name="communityImpact" label="What community(ies) will this project impact: (ex: city and state, county/parish)*"  maxLength={1000} component={TextInput} lines={5} />
														<Field className="cell" value={values.totalProjectBudget} name="totalProjectBudget" label="Total project budget:*" component={NumberInput} />
														<Field className="cell" value={values.monetaryRequest} name="monetaryRequest" label="Dollar amount requested:*" component={NumberInput} />
													</div>

													<div className={style.sectionTitle}>Section 5: Project Goals and Objectives</div>
													<div className="grid-x grid-margin-x medium-up-2">
														{/* <Field className="cell" name="projectType" label="Project Type:*" options={options} placeholder="-- Please select --" component={Select}/> */}
														<Field className="cell" value={values.projectAddresses} name="projectAddresses" label="What community challenges or needs will this project address:*" maxLength={1000} component={TextInput} lines={5} />
														<Field className="cell" value={values.howMeetGoal} name="howMeetGoal" label="How will you meet the project goal(s):*" maxLength={1000} component={TextInput} lines={5} />
														<Field className="cell" value={values.activitySeeking} name="activitySeeking" label="Specific activities for which you are seeking funding:*" maxLength={1000} component={TextInput} lines={5} />
														<Field className="cell" value={values.whoDoesActivities} name="whoDoesActivities" label="Who will carry out those activities:*" maxLength={1000} component={TextInput} lines={5} />
														<Field className="cell" value={values.measureImpact} name="measureImpact" label="How will you quantify the impact your project will have on the community:*" maxLength={1000} component={TextInput} lines={5} />
													</div>

													<div className="grid-x">
														<div className={[style.checkbox, "cell shrink"].join(' ')}>
															<Field type="checkbox" value={values.agreeToReport} checked={values.agreeToReport} name="agreeToReport" />
														</div>
														<div className="cell auto">
															<label htmlFor="agreeToReport" className="form-check-label">I agree to provide regular updates such as photos, personal testimonies and videos to Danos regarding the use of the grant funds. I agree to complete a Danos Foundation Community Impact Report no later than the end of the calendar year in which the grant was awarded. The report seeks to gain insight and data (where possible) on the impact the grant had on the community and if the original intent of the grant was fulfilled. In your Danos Foundation Community Impact Report please share with us:<br />
																<ul>
																	<li><strong>Outcomes:</strong> Who was served? In what geographical area? For how long? What successes did you experience?</li>
																	<li><strong>Impact:</strong> Please share personal stories highlighting the impact your initiative had on individuals or communities.</li>
																</ul>
															</label>
														</div>
													</div>

													<p>To be considered for future funding, recipients must return the impact report prior to submitting future grant applications.</p>

													<Field name="additionalInfo" setFieldValue={setFieldValue} label="Danos invites you to attach any additional information that you feel is relevant to this application:" component={Upload} accept={filesAccepted} />

													<Field value={values.howHearAbout} component={RadioButton} label="How did you hear about the Danos Foundation?*" name="howHearAbout" options={radioOptions} />

													<div className={[values.howHearAbout === 'danos_employee_name' ? '' : 'hide', "grid-x"].join(' ')}>
														<Field value={values.danosEmployeeName} className="cell" name="danosEmployeeName" label="Danos employee name:" component={TextInput} />
													</div>

													<div style={{ marginTop: '1rem', marginBottom: '1rem', position: 'relative' }}><div style={{ zIndex: 2, position: 'relative' }}><ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} onChange={this.recaptchaComplete} /></div><div className={[style.error, (this.state.recaptchaError ? style.active : '')].join(' ')}>You must complete the ReCAPTCHA</div></div>

													<button className="button primary" type="submit" disabled={isSubmitting}>Submit Application</button>

												</div>

												{<div className={[style.success, (this.state.success ? style.showSuccess : '')].join(' ')}>Thank you for your application and the good work you do in the community. You will receive a copy of your application via email for your records. Applications submitted before August 31 are considered for Danos GIVES grant awards that are distributed in January. Final decisions for grants are made in December and you will be notified at this time.</div>}
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</section>
				</Layout>
				<SEO title={'Danos GIVES Application | Danos Foundation'} />
			</>
		)
	}
}